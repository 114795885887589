import { useIsClient } from "hooks/useIsClient.tsx";
import { useTailwindScreenSizes } from "hooks/useTailwindScreenSizes.tsx";

const Devtool = ({
  initialScreenWidth = 1280,
}: {
  initialScreenWidth?: number;
}) => {
  const { currentWidth, getResponsive } = useTailwindScreenSizes({
    initialWidth: initialScreenWidth,
  });

  const isClient = useIsClient();
  if (!isClient) return null;
  return (
    <div
      className={`fixed bottom-0 left-0 z-40 flex flex-row gap-2 bg-yellow-500 font-normal text-black`}
    >
      <span>{currentWidth}</span>
      <span>{getResponsive()}</span>
    </div>
  );
};

export default Devtool;
