const FullLogo = () => {
  return (
    <svg
      width="160"
      height="20"
      viewBox="0 0 160 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2428 6.71862C13.7741 5.81522 13.1232 5.133 12.2901 4.67239C11.457 4.21214 10.4938 3.98162 9.4003 3.98162C8.20281 3.98162 7.1353 4.25123 6.19814 4.78969C5.26086 5.32851 4.52743 6.09297 3.99824 7.08351C3.46858 8.07405 3.20412 9.22096 3.20412 10.5243C3.20412 11.8276 3.46858 12.979 3.99824 13.9781C4.52743 14.9776 5.26083 15.7465 6.19814 16.285C7.1353 16.8238 8.20281 17.093 9.4003 17.093C11.0145 17.093 12.3247 16.6414 13.3315 15.7376C14.3379 14.8342 14.9542 13.6091 15.1799 12.0622H8.38494V9.69017H18.3562V12.0101C18.1649 13.4177 17.6663 14.7124 16.8592 15.894C16.0521 17.0759 15.0018 18.0184 13.7091 18.7221C12.4158 19.4259 10.9795 19.7779 9.4003 19.7779C7.69919 19.7779 6.1461 19.3828 4.74021 18.5918C3.33432 17.8013 2.21895 16.702 1.39477 15.2944C0.570231 13.8869 0.158203 12.2968 0.158203 10.5243C0.158203 8.75178 0.570231 7.16177 1.39477 5.75415C2.21895 4.34661 3.33838 3.24775 4.75316 2.45675C6.16758 1.66624 7.71665 1.27075 9.4003 1.27075C11.3269 1.27075 13.0406 1.74445 14.5422 2.69138C16.0432 3.6387 17.1326 4.98112 17.8095 6.71864H14.2428V6.71862Z"
        className="fill-current"
      />
      <path
        d="M23.8916 18.9305C22.798 18.3139 21.9389 17.4448 21.3141 16.3239C20.6893 15.2031 20.377 13.9042 20.377 12.427C20.377 10.9673 20.6978 9.67297 21.3401 8.54313C21.9821 7.4137 22.8588 6.54498 23.9696 5.9365C25.0801 5.32841 26.3212 5.02417 27.6926 5.02417C29.0639 5.02417 30.3046 5.32841 31.4155 5.9365C32.526 6.54495 33.4027 7.4137 34.045 8.54313C34.6869 9.67297 35.0081 10.9673 35.0081 12.427C35.0081 13.8868 34.6783 15.1815 34.0189 16.3109C33.3591 17.4407 32.4609 18.3139 31.3243 18.9305C30.1874 19.5476 28.9333 19.8559 27.5623 19.8559C26.2086 19.8559 24.985 19.5476 23.8916 18.9305ZM29.7102 16.7279C30.3781 16.363 30.9208 15.8156 31.3374 15.0858C31.7539 14.3559 31.9622 13.4697 31.9622 12.427C31.9622 11.3843 31.7625 10.5026 31.3634 9.78124C30.964 9.06036 30.4347 8.51703 29.7753 8.15209C29.1155 7.78719 28.404 7.60472 27.6405 7.60472C26.8766 7.60472 26.1695 7.78719 25.5187 8.15209C24.8678 8.51705 24.3513 9.06039 23.9696 9.78124C23.5877 10.5025 23.3969 11.3843 23.3969 12.427C23.3969 13.9739 23.7914 15.1684 24.5815 16.0111C25.371 16.8542 26.3648 17.2753 27.5623 17.2753C28.3259 17.2753 29.0419 17.0929 29.7102 16.7279H29.7102Z"
        className="fill-current"
      />
      <path
        d="M40.4384 18.9305C39.3449 18.3139 38.4858 17.4448 37.861 16.3239C37.2362 15.2031 36.9238 13.9042 36.9238 12.427C36.9238 10.9673 37.2447 9.67297 37.887 8.54313C38.5289 7.4137 39.4056 6.54498 40.5165 5.9365C41.627 5.32841 42.8681 5.02417 44.2394 5.02417C45.6107 5.02417 46.8515 5.32841 47.9623 5.9365C49.0728 6.54495 49.9495 7.4137 50.5918 8.54313C51.2338 9.67297 51.555 10.9673 51.555 12.427C51.555 13.8868 51.2252 15.1815 50.5658 16.3109C49.9059 17.4407 49.0077 18.3139 47.8712 18.9305C46.7343 19.5476 45.4801 19.8559 44.1092 19.8559C42.7555 19.8559 41.5319 19.5476 40.4384 18.9305ZM46.257 16.7279C46.925 16.363 47.4677 15.8156 47.8843 15.0858C48.3008 14.3559 48.5091 13.4697 48.5091 12.427C48.5091 11.3843 48.3094 10.5026 47.9103 9.78124C47.5109 9.06036 46.9815 8.51703 46.3222 8.15209C45.6624 7.78719 44.9509 7.60472 44.1874 7.60472C43.4234 7.60472 42.7164 7.78719 42.0656 8.15209C41.4147 8.51705 40.8981 9.06039 40.5165 9.78124C40.1345 10.5025 39.9437 11.3843 39.9437 12.427C39.9437 13.9739 40.3383 15.1684 41.1284 16.0111C41.9179 16.8542 42.9117 17.2753 44.1092 17.2753C44.8728 17.2753 45.5888 17.0929 46.2571 16.7279H46.257Z"
        className="fill-current"
      />
      <path
        d="M54.359 8.54314C54.9578 7.43126 55.7734 6.56657 56.8063 5.94955C57.8387 5.33293 58.9887 5.02418 60.2558 5.02418C61.1931 5.02418 62.1173 5.22866 63.0285 5.63673C63.9398 6.04524 64.6643 6.58814 65.2023 7.26589V0.0710449H68.1964V19.6213H65.2023V17.4578C64.7163 18.1531 64.0438 18.7266 63.1847 19.1782C62.3256 19.6299 61.3403 19.8559 60.2298 19.8559C58.9802 19.8559 57.8387 19.5391 56.8063 18.9045C55.7735 18.2704 54.9578 17.3841 54.359 16.2457C53.7603 15.1078 53.4609 13.8175 53.4609 12.3749C53.4609 10.9322 53.7603 9.65542 54.359 8.54314H54.359ZM64.5906 9.84649C64.1826 9.11663 63.6489 8.56069 62.9895 8.17824C62.3296 7.79616 61.6182 7.60475 60.8546 7.60475C60.091 7.60475 59.3792 7.79169 58.7198 8.16516C58.0601 8.5391 57.5264 9.08647 57.1187 9.80735C56.7107 10.5287 56.5069 11.3844 56.5069 12.3749C56.5069 13.3654 56.7107 14.2346 57.1187 14.9815C57.5264 15.7289 58.0645 16.2979 58.7329 16.6889C59.4008 17.0799 60.1082 17.2754 60.8546 17.2754C61.6182 17.2754 62.3296 17.0844 62.9895 16.7019C63.6489 16.3199 64.1826 15.7595 64.5906 15.0207C64.9982 14.2823 65.2023 13.4176 65.2023 12.4271C65.2023 11.4366 64.9982 10.5764 64.5906 9.84651V9.84649Z"
        className="fill-current"
      />
      <path
        d="M72.3655 5.75415C73.1897 4.34661 74.3091 3.24775 75.7239 2.45675C77.1383 1.66624 78.6874 1.27075 80.371 1.27075C82.2976 1.27075 84.0114 1.74445 85.5129 2.69138C87.0139 3.6387 88.1033 4.98112 88.7802 6.71864H85.2135C84.7448 5.76313 84.0939 5.05035 83.2608 4.58119C82.4277 4.11196 81.4645 3.87738 80.371 3.87738C79.1735 3.87738 78.106 4.14699 77.1688 4.68545C76.2316 5.22427 75.4981 5.99771 74.969 7.00532C74.4393 8.01337 74.1748 9.18638 74.1748 10.5243C74.1748 11.8622 74.4393 13.0356 74.969 14.0432C75.4981 15.0513 76.2315 15.8288 77.1688 16.3762C78.106 16.9236 79.1735 17.1973 80.371 17.1973C81.4645 17.1973 82.4277 16.9626 83.2608 16.4935C84.0939 16.0243 84.7448 15.3119 85.2135 14.356H88.7802C88.1033 16.0939 87.0139 17.4319 85.5129 18.3703C84.0114 19.3086 82.2976 19.7779 80.371 19.7779C78.6699 19.7779 77.1168 19.3828 75.7109 18.5918C74.305 17.8013 73.1897 16.702 72.3655 15.2944C71.5409 13.8869 71.1289 12.2968 71.1289 10.5243C71.1289 8.75178 71.5409 7.16177 72.3655 5.75415Z"
        className="fill-current"
      />
      <path
        d="M94.3857 18.9305C93.2922 18.3139 92.4331 17.4448 91.8083 16.3239C91.1834 15.203 90.8711 13.9042 90.8711 12.427C90.8711 10.9673 91.192 9.67297 91.8343 8.54313C92.4762 7.4137 93.3529 6.54498 94.4638 5.9365C95.5743 5.32841 96.8154 5.02417 98.1867 5.02417C99.558 5.02417 100.799 5.32841 101.91 5.9365C103.02 6.54495 103.897 7.4137 104.539 8.54313C105.181 9.67297 105.502 10.9673 105.502 12.427C105.502 13.8868 105.172 15.1815 104.513 16.3109C103.853 17.4407 102.955 18.3139 101.818 18.9305C100.682 19.5476 99.4274 19.8559 98.0565 19.8559C96.7027 19.8559 95.4791 19.5476 94.3857 18.9305ZM100.204 16.7279C100.872 16.363 101.415 15.8156 101.832 15.0858C102.248 14.3559 102.456 13.4697 102.456 12.427C102.456 11.3843 102.257 10.5026 101.858 9.78124C101.458 9.06036 100.929 8.51703 100.269 8.15209C99.6097 7.78719 98.8981 7.60472 98.1346 7.60472C97.3707 7.60472 96.6637 7.78719 96.0128 8.15209C95.362 8.51705 94.8454 9.06039 94.4638 9.78124C94.0818 10.5025 93.891 11.3843 93.891 12.427C93.891 13.9739 94.2856 15.1684 95.0757 16.0111C95.8652 16.8542 96.859 17.2753 98.0565 17.2753C98.8201 17.2753 99.536 17.0929 100.204 16.7279H100.204Z"
        className="fill-current"
      />
      <path
        d="M110.736 0.0710449V19.6213H107.768V0.0710449H110.736Z"
        className="fill-current"
      />
      <path
        d="M117.322 0.0710449V19.6213H114.354V0.0710449H117.322Z"
        className="fill-current"
      />
      <path
        d="M134.15 13.5478H123.189C123.276 14.6948 123.701 15.6161 124.465 16.3109C125.228 17.0061 126.166 17.3535 127.277 17.3535C128.873 17.3535 130.001 16.6848 130.661 15.3465H133.863C133.429 16.6673 132.644 17.749 131.507 18.5917C130.37 19.4348 128.96 19.8559 127.277 19.8559C125.905 19.8559 124.677 19.5476 123.593 18.9305C122.508 18.3139 121.657 17.4448 121.041 16.3239C120.425 15.2031 120.117 13.9042 120.117 12.427C120.117 10.9499 120.417 9.65095 121.015 8.53009C121.614 7.40924 122.456 6.54495 123.541 5.9365C124.625 5.32841 125.871 5.02417 127.277 5.02417C128.63 5.02417 129.836 5.31991 130.895 5.91045C131.954 6.50142 132.778 7.33103 133.369 8.39978C133.958 9.46848 134.254 10.6981 134.254 12.0881C134.254 12.627 134.219 13.1137 134.15 13.5478ZM131.156 11.1497C131.138 10.055 130.748 9.17769 129.984 8.51705C129.22 7.85685 128.274 7.52651 127.146 7.52651C126.122 7.52651 125.246 7.85234 124.517 8.50404C123.788 9.15569 123.354 10.0379 123.215 11.1497H131.156Z"
        className="fill-current"
      />
      <path
        d="M137.06 8.53009C137.659 7.40924 138.488 6.54495 139.547 5.9365C140.605 5.32841 141.82 5.02417 143.191 5.02417C144.927 5.02417 146.363 5.43718 147.5 6.26233C148.637 7.08788 149.405 8.26538 149.804 9.7943H146.602C146.342 9.08199 145.925 8.52606 145.352 8.12606C144.779 7.72654 144.059 7.52651 143.191 7.52651C141.976 7.52651 141.009 7.9566 140.289 8.81683C139.568 9.677 139.208 10.8805 139.208 12.427C139.208 13.9735 139.568 15.1814 140.289 16.0502C141.009 16.9193 141.976 17.3535 143.191 17.3535C144.91 17.3535 146.046 16.5976 146.602 15.0857H149.804C149.388 16.5454 148.606 17.7054 147.461 18.5656C146.315 19.4258 144.892 19.8559 143.191 19.8559C141.82 19.8559 140.605 19.5476 139.547 18.9305C138.488 18.3139 137.659 17.4447 137.06 16.3239C136.462 15.203 136.162 13.9042 136.162 12.427C136.162 10.9498 136.462 9.6509 137.06 8.53005V8.53009Z"
        className="fill-current"
      />
      <path
        d="M155.958 7.68305V15.6333C155.958 16.1721 156.083 16.5586 156.335 16.7933C156.586 17.0278 157.016 17.1451 157.624 17.1451H159.446V19.6215H157.103C155.766 19.6215 154.743 19.3087 154.031 18.6831C153.319 18.0575 152.964 17.0409 152.964 15.6333V7.68308H151.271V5.25887H152.964V1.37451H155.958V5.25887H159.446V7.68308H155.958L155.958 7.68305Z"
        className="fill-current"
      />
    </svg>
  );
};

export default FullLogo;
